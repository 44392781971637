.resultaten {
    text-align: left;
}

.chakra-stack * {
    /*border: 1px solid black;*/
}

.vraagstelling {
    padding: 0 1em 0 1em;
    height: 3em;
}
