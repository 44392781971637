.RadioGroup {
    padding: 24px 0px 24px 0px;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: flex-start;*/
}

.RadioOption {
  margin: 4px;
  padding: 14px 8px 14px 8px;
  cursor: pointer;
  font-weight: bold;
  border: 2px solid gainsboro;
  border-radius: 5px;
  box-shadow: 5px 5px #f6f6f6;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/*.RadioOption :not(.selected) {*/
/*     border: 1px dotted black;*/
/*}*/

.selected {
    border: 2px solid blue;
    background-color: gainsboro;
}
